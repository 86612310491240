<template>
  <div>
    <div v-if="!isMobile" style="max-width: 1200px;margin:0 auto">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/robot'}" :replace="false">{{ items.robot_type == 'bn'?'Binance':'OKX' }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('robot.strategy_details') }}<span v-if="switchValue != 2" style="display: inline-block;margin-left: 10px">(NO.{{items.rank<10?'0'+items.rank:items.rank}})</span></el-breadcrumb-item>
      </el-breadcrumb>

      <el-card style="margin-top: 12px" shadow="hover">
        <div class="detail_title">
          <img v-if="items.strategyType == 0" src="../../assets/img/robot/robot_strategy/strategy_sj.svg">
          <img v-if="items.strategyType == 1" src="../../assets/img/robot/robot_strategy/strategy_bb.svg">
          <img v-if="items.strategyType == 2" src="../../assets/img/robot/robot_strategy/strategy_ub.svg">
          <span v-if="items.strategyType == 0">{{ items.coin_one }} <span style="color: #52C41A">⇀</span> {{ items.coin_two }} <span style="color: #52C41A;background-color: unset;padding: 0;margin: 0">⇀</span> {{ items.coin_three }}</span>
          <span v-if="items.strategyType == 1">{{ items.coin_one }} <div style="display: inline-block;position:unset;vertical-align: middle"><span style="display: block;color: #52C41A">⇀</span><span  v-if="items.space_two != 0" style="display: block;font-size:16px;margin:0px;padding:0;height:9px;background-color:unset;color:#FF4D4F;transform: scale(-1.1);">⇀</span></div> {{ items.coin_two }} </span>
          <span v-if="items.strategyType == 2">{{ items.coin_one }} <span style="color: #52C41A">⇀</span> {{ items.coin_two }} </span>
          <span v-if="items.strategyType == 0">{{ $t('robot.auto') }}：{{ $t('robot.golden_triangle_arbitrage') }}</span>
          <span v-if="items.strategyType == 1">{{ $t('robot.auto') }}：{{ $t('robot.bb_trade') }}</span>
          <span v-if="items.strategyType == 2">{{ $t('robot.auto') }}：{{ $t('robot.ub_trade') }}</span>

          <span v-if="(items.strategyType == 0) && items.state == 1 && items.capital == 0 && robotState != 2 && switchValue != 2" style="display:inline-block;vertical-align:middle;font-size:12px;color: #AB54DB;margin:0 10px"><span class="el-icon-refresh-right" style="color: #AB54DB;margin-right:2px;font-size: 16px;animation: rotating 1.2s linear infinite;vertical-align: top;font-weight: unset;"></span>正在初始化</span>
          <img v-if="robotState != 2 && (((items.strategyType == 0) && items.state == 1 && items.capital != 0) || items.strategyType == 1 || items.strategyType == 2 || items.state == 0) && switchValue != 2" style="vertical-align: middle;margin-left: 16px"src="../../assets/img/robot/running.gif">
          <span style="vertical-align: middle">{{ $t('robot.create_time') }} {{ items.add_time }}</span>
          <div v-if="switchValue != 2">
            <el-button class="detail_title_button_w" @click="delet(items.strategyType, items)">{{ $t('robot.del_strategy') }}</el-button>
            <!--          <el-button v-show="switchValue == 1" class="detail_title_button_b">暫停策略</el-button>-->
          </div>
        </div>
      </el-card>

      <!--    <el-card class="total_revenue" style="margin-top: 0px;position: relative" shadow="hover">-->
      <!--      <div class="total_revenue_content">-->
      <!--        <div>-->
      <!--          <div>-->
      <!--            <span style="margin-bottom: 8px">總收益</span>-->
      <!--            <span style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #1E2329;">435,5.37 USDT</span>-->
      <!--          </div>-->
      <!--          <div class="">-->
      <!--            <span style="margin-bottom: 8px">收益率 | 縂年化</span>-->
      <!--            <span style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #52C41A;">9.69% | 2,423.55%</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div style="width: 97%;height: 1px;background: #EAECEF;margin: 20px 3% 20px 0"></div>-->
      <!--        <div style="width: 100%; position:relative;">-->
      <!--          <div style="display: inline-block;width: 27%;vertical-align: middle;">-->
      <!--            <span style="margin-bottom: 8px">浮动盈亏</span>-->
      <!--            <span class="total_revenue_content_bottom" style="">-20.93 USDT</span>-->
      <!--          </div>-->
      <!--          <div style="display: inline-block;width: 27%;vertical-align: middle;">-->
      <!--            <span style="margin-bottom: 8px">投资额</span>-->
      <!--            <span class="total_revenue_content_bottom" style="">200 USDT</span>-->
      <!--          </div>-->
      <!--          <div style="display: inline-block;width: 27%;vertical-align: middle;">-->
      <!--            <span style="margin-bottom: 8px">今日收益</span>-->
      <!--            <span class="total_revenue_content_bottom" style="">11.05USDT</span>-->
      <!--          </div>-->
      <!--          <el-button class="detail_title_button_w" style="width: 16%;margin-right: 3%;vertical-align: middle;">查看排行</el-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="total_revenue_content" style="border-left: 1px #EAECEF solid;position:absolute;padding-left: 1%;">-->
      <!--        <charts-line></charts-line>-->
      <!--      </div>-->
      <!--    </el-card>-->

      <el-card class="total_revenue" style="margin-top: 0px;position: relative" shadow="hover">
        <div class="total_revenue_content" style="width: 100%">
          <span style="font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">{{ $t('robot.strategy_details') }}</span>
          <div style="width: 100%;height: 1px;background: #EAECEF;margin: 16px 3% 16px 0"></div>
          <div style="width: 100%; position:relative; display: flex; justify-content: center">
            <!--          <div style="display: inline-block;width: 25%;vertical-align: middle;">-->
            <!--            <span style="margin-bottom: 8px">当前持有资产</span>-->
            <!--            <span class="total_revenue_content_bottom" style="">0.5BTC 100DOT 20BNB</span>-->
            <!--          </div>-->
            <div style="display: inline-block;width: 50%;vertical-align: middle;">
              <span style="margin-bottom: 8px">{{ $t('robot.initial_held_assets') }}</span>
              <span v-if="items.strategyType == 0" class="total_revenue_content_bottom" style="">{{ items.coin_one }}({{ items.space_one | roundEightFilter }})、{{ items.coin_two }}({{ items.space_two | roundEightFilter }})、{{ items.coin_three }}({{ items.space_three | roundEightFilter }})</span>
              <span v-if="items.strategyType == 1" class="total_revenue_content_bottom" style="">{{ items.coin_one }}({{ items.space | roundEightFilter }})、{{ items.coin_two }}({{ items.space_two | roundEightFilter }})</span>
              <span v-if="items.strategyType == 2" class="total_revenue_content_bottom" style="">{{ items.coin_one }}({{ items.space | roundEightFilter }})、{{ items.coin_two }}</span>
            </div>
            <div style="display: inline-block;width: 50%;vertical-align: middle;">
              <span style="margin-bottom: 8px">{{ $t('robot.create_time') }}</span>
              <span class="total_revenue_content_bottom" style="">{{ items.add_time }}</span>
            </div>
            <!--          <div style="display: inline-block;width: 33%;vertical-align: middle;">-->
            <!--            <span style="margin-bottom: 8px">運行時常</span>-->
            <!--            <span class="total_revenue_content_bottom" style="">{{ runTimeToHour }}小時</span>-->
            <!--          </div>-->
          </div>
        </div>
      </el-card>

      <el-card class="" shadow="hover">
        <div class="everyday-top">
          <el-tabs v-model="lishijiaoyi" @tab-click="handleSelect">
            <el-tab-pane :label="lishitotal?$t('robot.current_delegation')+'('+ lishitotal +')':$t('robot.current_delegation')" name="0"></el-tab-pane>
            <el-tab-pane :label="lishitotal2?$t('robot.history_delegation')+'('+ lishitotal2 +')':$t('robot.history_delegation')" name="1"></el-tab-pane>
            <el-tab-pane :label="lishitotal3?$t('robot.history_transaction')+'('+ lishitotal3 +')':$t('robot.history_transaction')" name="2"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="robot-pc">
          <el-date-picker
              v-if="lishijiaoyi == 1 || lishijiaoyi == 2"
              style="float:right;"
              v-model="dateValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('robot.start_date')"
              :end-placeholder="$t('robot.end_date')"
              @change="selectDate">
          </el-date-picker>
        </div>

        <div class="robot-pc">
          <el-table
              :key="lishijiaoyi"
              :data="lishijiaoyiList_1"
              style="width: 100%">
            <el-table-column v-if="lishijiaoyi == 0 || lishijiaoyi == 2" align="center" prop="deal_time" :label="$t('robot.date')">
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 1" align="center" prop="deal_time" :label="$t('robot.time')">
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 0 || lishijiaoyi == 1 || lishijiaoyi == 2"
                align="center"
                prop="instrument_id"
                :label="$t('robot.transaction_pair')">
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 0 || lishijiaoyi == 1 || lishijiaoyi == 2"
                align="center"
                prop="side"
                :label="$t('robot.side')">
              <template slot-scope="scope">
                <span :class="[scope.row.side === 'buy'?'side_color_green':'side_color_red']">{{ scope.row.sideName }}</span>
              </template>
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 0 || lishijiaoyi == 1"
                align="center"
                prop="price"
                :formatter="stateFormat2"
                :label="$t('robot.price')">
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 2"
                align="center"
                prop="price"
                :label="$t('robot.transaction_price')">
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 0"
                align="center"
                prop="size"
                :label="$t('robot.num')">
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 1 || lishijiaoyi == 2"
                align="center"
                prop="size"
                :label="$t('robot.number_of_transactions')">
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 1"
                align="center"
                prop="size"
                :label="$t('robot.entrusted_quantity')">
            </el-table-column>
            <el-table-column
                v-if="lishijiaoyi == 1"
                align="center"
                prop="earn"
                :formatter="stateFormat3"
                :label="$t('robot.all')">
            </el-table-column>
          </el-table>
          <el-pagination
              style="text-align: right;margin: 24px"
              layout="prev, pager, next"
              @current-change="handleRecommendCurrentChange"
              :page-size="10"
              :current-page="pageNum"
              :hide-on-single-page="capitalListTotal<=0"
              :total="capitalListTotal">
          </el-pagination>
        </div>
      </el-card>
    </div>
    <div v-else style="">
      <div style="height: 50px;background-color: white;position:relative;">
        <i class="el-icon-arrow-left" style="display:inline-block;font-size: 20px;line-height: 50px;margin-left: 20px" @click="$router.back()"></i>
        <span style="display:inline-block;position: absolute; margin: auto; left: 50%; top: 50%;transform: translate(-50%, -50%);">{{ $t('robot.strategy_details') }}<span v-if="switchValue != 2" style="display: inline-block;margin-left: 5px">(NO.{{items.rank<10?'0'+items.rank:items.rank}})</span></span>
        <img v-if="items.robot_type == 'bn'" style="position: absolute;right: 0;top: 0;width: 20px;height: 22px" src="../../assets/img/robot/binance_sign.svg">
        <img v-if="items.robot_type == 'ok'" style="position: absolute;right: 0;top: 0;width: 20px;height: 22px" src="../../assets/img/robot/okex_sign.svg">
      </div>
      <el-card style="" shadow="hover">
        <div class="detail_title" style="border-bottom: unset">
          <span v-if="items.strategyType == 0" style="font-size: 16px;margin-right: 9px;display: block">{{ items.coin_one }} <span style="color: #52C41A;margin-top: 0">⇀</span> {{ items.coin_two }} <span style="color: #52C41A;background-color: unset;font-size: 16px;margin: -3px 0 0 0;padding: 0px;font-weight: 600;">⇀</span> {{ items.coin_three }}</span>
          <span v-if="items.strategyType == 1" style="font-size: 16px;margin-right: 9px">{{ items.coin_one }} <div style="display: inline-block;position:unset;vertical-align: middle"><span :class="($util.isAndroid())?'array-android-css2':''" style="display: block;font-size:16px;color: #52C41A;line-height: 6px;margin-top: 0">⇀</span><span :class="$util.isAndroid()?'array-android-css': ''" v-if="items.space_two != 0" style="display: block;font-size:16px;margin:0;padding:0;background-color:unset;color:#FF4D4F;transform: rotate(180deg);line-height: 6px">⇀</span></div> {{ items.coin_two }} </span>
          <span v-if="items.strategyType == 2" style="display:inline-block;width: fit-content;position:unset;margin-top: 0;margin-right: 9px;">
          <span style="display: inline-block;font-size: 16px;margin-top: 2px">{{ items.coin_one }}</span>
          <span style="display: inline-block;color: #52C41A;line-height: 12px;margin: 0 2px 2px;background-color: transparent;padding: 0;font-size: 16px">⇀</span>
          <span style="display: inline-block;font-weight: 600;margin: 0;color: black;font-size: 16px">{{ items.coin_two }} </span>
        </span>

          <span v-if="items.strategyType == 0" style="padding: 4px 10px;font-size: 14px;margin-left: 0px;width: fit-content">{{ $t('robot.auto') }}：{{ $t('robot.golden_triangle_arbitrage') }}</span>
          <span v-if="items.strategyType == 1" style="padding: 4px 10px;font-size: 14px;margin-left: 0px;">{{ $t('robot.auto') }}：{{ $t('robot.bb_trade') }}</span>
          <span v-if="items.strategyType == 2" style="padding: 4px 10px;font-size: 14px;margin-left: 0px;">{{ $t('robot.auto') }}：{{ $t('robot.ub_trade') }}</span>

          <!--        <div style="display: inline-block;position: relative;vertical-align: middle">-->
          <span v-if="(items.strategyType == 0) && items.state == 1 && items.capital == 0 && robotState != 2 && switchValue != 2" style="display:inline-block;vertical-align:middle;font-size:12px;color: #AB54DB;margin-left:10px;"><span class="el-icon-refresh-right" style="color: #AB54DB;margin-right:2px;font-size: 16px;animation: rotating 1.2s linear infinite;vertical-align: top;font-weight: unset;"></span>正在初始化</span>
          <img v-if="robotState != 2 && (((items.strategyType == 0) && items.state == 1 && items.capital != 0) || items.strategyType == 1 || items.strategyType == 2 || items.state == 0) && switchValue != 2" style="vertical-align: middle;height: unset; width: 20px;margin-left:10px" src="../../assets/img/robot/running.gif">
          <!--        </div>-->

          <span style="display: block;font-size: 12px;margin-left: 0px;margin-top: 5px">{{ $t('robot.create_time') }} {{ items.add_time }}</span>
          <!--        <span style="position:absolute; right: 0;top: 5px; font-size: 14px">運行中</span>-->
          <!--        <div>-->
          <!--          <el-button class="detail_title_button_w">刪除策略</el-button>-->
          <!--          <el-button v-show="switchValue == 1" class="detail_title_button_b">暫停策略</el-button>-->
          <!--        </div>-->
          <div v-if="switchValue != 2" style="display: inline-block;position: absolute;right: 0;top: 0">
            <el-button class="detail_title_button_w" style="display: block;margin: 3px;padding: 4px 15px;" @click="delet(items.strategyType, items)">{{ $t('robot.del') }}</el-button>
          </div>
        </div>
      </el-card>
      <el-card style="margin-top: 12px;min-height: 500px" shadow="hover">
        <el-tabs style="" v-model="celue_type" @tab-click="celueType">
          <!--        <el-tab-pane label="收益詳情" name="1"></el-tab-pane>-->
          <el-tab-pane :label="$t('robot.policy_information')" name="2"></el-tab-pane>
          <el-tab-pane :label="$t('robot.policy_delegation')" name="3"></el-tab-pane>
        </el-tabs>
        <div v-if="celue_type == 1" class="">
          <span style="font-size: 14px">總收益(<span style="color: #72777E">USDT</span>)</span>
          <span style="display:block;margin-top:6px;font-size: 22px;font-weight: 600">-73.2<span style="font-size: 12px;color: #de5a58;font-weight: 300;margin-left: 10px">-24%</span></span>
          <span style="display: block;font-size: 12px;margin-left: 0px;margin-top: 5px">运行时长1日15时1分20秒 (2021/12/02 23:21:21 创建)</span>
          <div style="width: 100%;height: 1px;background: #EAECEF;margin: 15px 3% 15px 0"></div>
          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">浮動盈虧</span>
            <span style="display:inline-block;font-size:12px;width: 12px;height: 12px; text-align: center;vertical-align: middle;margin-left: 5px;:  color: #72777E;border: 1px #72777E solid; border-radius: 50%">?</span>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">0.5 USDT</span>
          </div>
          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">投資額</span>
            <span style="display:inline-block;font-size:12px;width: 12px;height: 12px; text-align: center;vertical-align: middle;margin-left: 5px;:  color: #72777E;border: 1px #72777E solid; border-radius: 50%">?</span>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">0.5 USDT</span>
          </div>
          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">今日收益</span>
            <span style="display:inline-block;font-size:12px;width: 12px;height: 12px;text-align: center;vertical-align: middle;margin-left: 5px;:  color: #72777E;border: 1px #72777E solid; border-radius: 50%">?</span>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">0.5 USDT</span>
          </div>
          <span style="display:block;font-size: 14px;margin-top: 50px">收益變化</span>
          <charts-line style="margin-top: 10px"></charts-line>
        </div>

        <div v-if="celue_type == 2" class="" style="font-size: 14px;background-color: #FEFBF3">
          <div style="display:inline-block;width: 50%; position:relative;background-color: #FEFBF3;border-radius: 2px;padding: 17px 0px;vertical-align: top">
            <!--          <div style="display: inline-block;width: 50%;vertical-align: middle;">-->
            <!--            <span style="margin:0 0px 8px 10px;display:inline-block;color: #72777E;font-weight: 300">當前持有資產</span>-->
            <!--            <span style="display:block;margin-left: 10px;color:#1E2329;font-weight: 500">{{ items.symbols[0].coin_one }}({{ items.symbols[0].space }})、{{ items.symbols[1].coin_two }}({{ items.symbols[1].space }})、{{ items.symbols[2].coin_three }}({{ items.symbols[2].space }})</span>-->
            <!--          </div>-->
            <div style="display: inline-block;width: 100%;vertical-align: middle;">
              <span style="margin-left:10px;margin-bottom: 8px;display:inline-block;color: #72777E;font-weight: 300">{{ $t('robot.initial_held_assets') }}</span>
              <!--            <span style="display:block;margin-left:10px;color:#1E2329;font-weight: 500">{{ items.symbols[0].coin_one }}({{ items.symbols[0].space }})、{{ items.symbols[1].coin_two }}({{ items.symbols[1].space }})、{{ items.symbols[2].coin_three }}({{ items.symbols[2].space }})</span>-->
              <span v-if="items.strategyType == 0" style="display:block;margin-left:10px;color:#1E2329;font-weight: 500"><span class="auto-strategy-position-detail">{{ items.coin_one }}({{ items.space_one }}</span>)、<span class="auto-strategy-position-detail">{{ items.coin_two }}({{ items.space_two }}</span>)、<span class="auto-strategy-position-detail">{{ items.coin_three }}({{ items.space_three }}</span>)</span>
              <span v-if="items.strategyType == 1" style="display:block;margin-left:10px;color:#1E2329;font-weight: 500"><span class="auto-strategy-position-detail">{{ items.coin_one }}({{ items.space }}</span>)、<span class="auto-strategy-position-detail">{{ items.coin_two }}({{ items.space_two }}</span>)</span>
              <span v-if="items.strategyType == 2" style="display:block;margin-left:10px;color:#1E2329;font-weight: 500"><span class="auto-strategy-position-detail">{{ items.coin_one }}({{ items.space }}</span>)、{{ items.coin_two }}</span>
            </div>
          </div>
          <div style="display:inline-block;width: 50%; position:relative;background-color: #FEFBF3;border-radius: 2px;padding: 17px 0px;vertical-align: top">
            <div style="display: inline-block;width: 100%;vertical-align: middle;">
              <span style="margin:0 0px 8px 10px;display:inline-block;color: #72777E;font-weight: 300">{{ $t('robot.create_time') }}</span>
              <span style="display:block;margin-left: 10px;color:#1E2329;font-weight: 500">{{ items.add_time }}</span>
            </div>
            <!--          <div style="display: inline-block;width: 50%;vertical-align: middle;">-->
            <!--            <span style="margin-bottom: 8px;display:inline-block;color: #72777E;font-weight: 300">運行時長</span>-->
            <!--            <span style="display:block;color:#1E2329;font-weight: 500">{{ runTimeToHour }}小时</span>-->
            <!--          </div>-->
          </div>
        </div>
        <div v-if="celue_type == 3" class="" style="width: 100%;">
          <div class="everyday-top">
            <el-tabs v-model="lishijiaoyi" @tab-click="handleSelect">
              <el-tab-pane :label="this.lishitotal?$t('robot.current_delegation')+'('+ this.lishitotal +')':$t('robot.current_delegation')" name="0"></el-tab-pane>
              <el-tab-pane :label="this.lishitotal2?$t('robot.history_delegation')+'('+ this.lishitotal2 +')':$t('robot.history_delegation')" name="1"></el-tab-pane>
              <el-tab-pane :label="this.lishitotal3?$t('robot.history_transaction')+'('+ this.lishitotal3 +')':$t('robot.history_transaction')" name="2"></el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="(lishijiaoyi == 1 || lishijiaoyi == 2) && isMobile" class="robot-earn-mb" style="text-align: center">
            <el-date-picker
                style="display: inline-block;width: 48%"
                v-model="date1"
                type="date"
                value-format="yyyy-MM-dd"
                range-separator="-"
                :placeholder="$t('robot.start_date')"
                @change="selectDateForMB1">
            </el-date-picker>
            <el-date-picker
                style="display: inline-block;width: 48%; float: right;"
                v-model="date2"
                type="date"
                value-format="yyyy-MM-dd"
                range-separator="-"
                :placeholder="$t('robot.end_date')"
                @change="selectDateForMB2">
            </el-date-picker>
          </div>
          <div class="robot-earn-mb" style="width: 100%;">
            <div v-if="lishijiaoyi == 0">
            </div>
            <table v-if="isMobile" class="table" style="width: 100%;" >
              <tbody>
              <tr v-for="(item, i) in lishijiaoyiList_1_mb">
                <td style="text-align: unset; width: 100%; font-size: 16px; border-bottom: #EAECEF 1px solid;padding:6px 0">
                  <div>
                    <div style="width: 100%;height: 26px; line-height: 26px;">
                      <span style="color: #000613;font-size:13px;font-weight: 550;">{{ item.instrument_id }}</span>
                      <span :class="[item.side === 'buy'?'side_color_green':'side_color_red']" id="sideColor" style="margin-left: 10px;font-size: 13px">{{ item.sideName }}</span>
                      <a v-if="lishijiaoyi == 1 && item.state == -1" style="float:right;color: #72777E;font-size: 12px">{{ $t('robot.cancelled') }}</a>
                      <a v-if="lishijiaoyi == 1 && item.state != -1" style="float:right;color: #72777E;font-size: 12px">{{ $t('robot.complete_deal') }}</a>
                      <span v-if="lishijiaoyi == 2"
                            style="float:right;color: #72777E;font-size: 12px">{{ item.deal_time }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 0 || lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.price') }}</span><span
                        style="float: right;color: #000613">{{ item.side === 'buy' ? item.price : item.price }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 0" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.num') }}</span><span
                        style="float: right;color: #000613">{{ item.side === 'buy' ? item.size : item.size }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.number_of_transactions') }}</span><span style="float: right;color: #000613">{{ item.size }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.entrusted_quantity') }}</span><span style="float: right;color: #000613">{{ item.size }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 0" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.date') }}</span><span style="float: right;color: #000613">{{ item.deal_time }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.time') }}</span><span style="float: right;color: #000613">{{ item.deal_time }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.average_transaction_price') }}</span><span style="float: right;color: #000613">{{ item.price }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.number_of_transactions') }}</span><span style="float: right;color: #000613">{{ item.size }}</span>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="isMobile" style="height: 22px;margin-top: 10px">
              <span style="font-size:14px; color: #000613; line-height: 22px;">{{ $t('robot.total') }} <span>{{ totalRowofMb }}</span> {{ $t('robot.strip') }}</span>
              <el-pagination
                  style="float:right;padding-right: 0px;"
                  small
                  layout="prev, pager, next"
                  @current-change="loadLishijiaoyi_1_mb"
                  :page-size="10"
                  :current-page="pageNumofMb"
                  :hide-on-single-page="totalRowofMb<=0"
                  :total="totalRowofMb">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 删除BB交易 -->
    <el-dialog
        class="edit_bb_dialog"
        :title="$t('robot.del') + $t('robot.bb_trade')"
        :visible.sync="delBbStrategy"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">

      <el-divider></el-divider>
      <div style="padding: 0 0px">
        <div v-if="robotState != 2" class="" style="margin-top: 20px">
          <el-radio style="display: block" v-model="stopState" label="3">{{ $t('robot.delete') }}{{ $t('robot.strategy_cancel_order') }}</el-radio>
          <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio1') }}</span>
          <span v-if="stopState == '3'" style="display:block;color: #FF4D4F;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_cancel_order_radio2_tip') }}</span>
        </div>

        <div v-if="robotState != 2" class="" style="margin-top: 20px">
          <el-radio v-model="stopState" label="2">{{ $t('robot.delete') }}{{ $t('robot.strategy_no_cancellation') }}</el-radio>
          <span style="display:block;color: #8E9298;margin-left: 23px;margin-top: 3px">{{ $t('robot.strategy_no_cancellation_radio1') }}</span>
        </div>

        <span v-if="robotState == 2" style="display:block;color: #606266;margin-top: 6px;">{{ $t('robot.delete_sj_for_closed_bot') }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="delBbStrategy = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="delBbNew()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>
  </div>

</template>

<script>
import {
  delBb, delGrid,
  delSj, delUb,
  getOrderListByStateAndSymbol,
  getSellOrdersByState,
  getSellOrdersByState2
} from "../../server/robot";
import ChartsLine from "./chartsLine";

export default {
  name: "Robot_auto_detail",
  components: {ChartsLine},
  data(){
    return{
      dateValue: [],
      date1: '',
      date2: '',
      capitalListTotal: 10,
      pageNum: 1,
      pageNumofMb: 1,
      totalPage: 0,
      totalPageofMb: 0,
      totalRowofMb: 10,
      lishijiaoyi: 0, //0：當前委托  1：歷史委托  2：歷史成交
      lishitotal: 0,
      lishitotal2: 0,
      lishitotal3: 0,
      lishijiaoyiObj: {},
      lishijiaoyiList_1: [],
      lishijiaoyiList_1_mb: [],
      isMobile: false,
      switchValue: 2,
      items: {},
      orderId:'',
      celue_type: '2',
      runTime: '',
      runTimeToHour: '',
      robotType: 0,
      robotState: '',
      delBbStrategy: false,
      delBBItem: {},
      stopState: '',
    }
  },

  mounted() {
    this.orderId = this.$route.query.id
    this.switchValue = this.$route.query.switchValue;
    this.robotState = this.$route.query.robotState;
    this.items = sessionStorage.getItem('detailItem')?JSON.parse(sessionStorage.getItem('detailItem')):{}
    if (this.items.robot_type == 'bn'){
      this.robotType = 0;
    } else {
      this.robotType = 1;
    }
    this.getRuntime();

    if (this.$util._isMobile()){
      if (this.$util._isMobile[0] == 'iPad'){
        this.isMobile = false
      }
      this.isMobile = true;
    } else {
      this.isMobile = false
    }
    this.initData();
  },

  methods:{
    initData(){
      if (this.isMobile){
        this.getSellOrdersByStateMb();
      }else {
        this.getSellOrdersByState();
      }
    },
    selectDate() {
      this.pageNum = 1
      if(!this.dateValue){
        this.date1 = ''
        this.date2 = ''
        this.capitalListTotal = 0
        this.lishijiaoyiList_1 = []
        if (this.lishijiaoyi == 1){
          this.lishitotal2 = 0
        } else {
          this.lishitotal3 = 0
        }
        return;
      }
      this.date1 = this.dateValue[0]
      this.date2 = this.dateValue[1]
      this.getSellOrdersByState2();
    },
    selectDateForMB1() {
      if (!this.date1){
        this.date1 = ''
        this.date2 = ''
        if (this.lishijiaoyi == 1){
          this.lishitotal2 = 0
        } else {
          this.lishitotal3 = 0
        }
        this.lishijiaoyiList_1_mb = []
        this.totalRowofMb = 0
        return;
      }
      this.pageNumofMb = 1;
      if (this.date2 == null || this.date2 == '') {
        return;
      } else {
        if (new Date(this.date2) < new Date(this.date1)) {
          this.$message.warning(this.$t('robot.end_time_cannot_less_than_start'))
          return
        }
        this.getSellOrdersByState2Mb();
      }
    },
    selectDateForMB2() {
      if (!this.date2){
        this.date1 = ''
        this.date2 = ''
        if (this.lishijiaoyi == 1){
          this.lishitotal2 = 0
        } else {
          this.lishitotal3 = 0
        }
        this.lishijiaoyiList_1_mb = []
        this.totalRowofMb = 0
        return;
      }
      if (this.date1 == null || this.date1 == '') {
        return;
      } else {
        if (new Date(this.date2) < new Date(this.date1)) {
          this.$message.warning(this.$t('robot.start_time_cannot_creater_than_end'))
          return
        }
        this.getSellOrdersByState2Mb();
      }
      this.pageNumofMb = 1;
    },
    handleSelect() {
      if (this.lishijiaoyi == 1 || this.lishijiaoyi == 2){
        if (!this.date1){
          this.date1 = this.$util.currentDate('-')
        }
        if (!this.date2){
          this.date2 = this.$util.currentDate('-')
        }
        if (!this.isMobile){
          if (this.dateValue == null || this.dateValue == [] || this.dateValue.length == 0){
            this.dateValue = []
            this.dateValue.push(this.date1)
            this.dateValue.push(this.date2)
          }
        }
      }
      this.pageNum = 1;
      this.pageNumofMb = 1;
      this.lishijiaoyiList_1_mb = [];
      this.lishijiaoyiList_1 = [];
      if (this.lishijiaoyi == 0) {
        if (this.isMobile){
          this.getSellOrdersByStateMb();
        }else{
          this.getSellOrdersByState();
        }
      } else {
        if (this.isMobile){
          this.getSellOrdersByState2Mb();
        }else{
          this.getSellOrdersByState2();
        }
      }
    },
    // 獲取當前委托
    async getSellOrdersByState() {
      let productType = 1;
      let symbol = '';
      switch (this.items.strategyType) {
        case 0:
          productType = 3;
          symbol = this.items.belong_to;
          break;
        case 1:
          productType = 2;
          symbol = this.items.coin;
          break;
        case 2:
          productType = 1;
          symbol = this.items.coin;
          break;
      }
      // const res = await getSellOrdersByState(this.token, this.lishijiaoyi, this.pageNum,10)state,startDate,endDate,productType,symbol,pageNum,pageSize
      const res = await getOrderListByStateAndSymbol(this.lishijiaoyi,'','',productType, symbol, this.pageNum,10,this.robotType,this.items.uid_one, this.items.uid_two)
      if (!res) return
      if (res.success) {
        this.lishijiaoyiObj = res.data
        this.lishijiaoyiList_1 = res.data.list;
        this.lishijiaoyiList_1.forEach((item, index)=> {
          if (item.side == 'buy') {
            item.sideName = this.$t('robot.buy')
          } else if (item.side == 'sell' || item.side == 'ration') {
            item.sideName = this.$t('robot.sell')
          }
        })
        this.capitalListTotal = res.data.totalRow
        if(this.lishijiaoyi == 0){
          this.lishitotal = res.data.totalRow // title展示用
        }

      } else {
        this.$message.error(res.message)
      }
    },
    // 獲取當前委托
    async getSellOrdersByState2() {
      let productType = 1;
      let symbol = '';
      switch (this.items.strategyType) {
        case 0:
          productType = 3;
          symbol = this.items.belong_to;
          break;
        case 1:
          productType = 2;
          symbol = this.items.coin;
          break;
        case 2:
          productType = 1;
          symbol = this.items.coin;
          break;
      }
      // const res = await getSellOrdersByState2(this.lishijiaoyi, this.date1, this.date2, this.pageNum,10)
      const res = await getOrderListByStateAndSymbol(this.lishijiaoyi,this.date1,this.date2,productType, symbol, this.pageNum,10,this.robotType,this.items.uid_one, this.items.uid_two)
      if (res.success) {
        this.lishijiaoyiList_1 = res.data.list;
        this.lishijiaoyiList_1.forEach((item, index)=> {
          if (item.side == 'buy') {
            item.sideName = this.$t('robot.buy');
          } else if (item.side == 'sell' || item.side == 'ration') {
            item.sideName = this.$t('robot.sell');
          }
        })
        if(this.lishijiaoyi == 1){
          this.lishitotal2 = res.data.totalRow
        }else if(this.lishijiaoyi == 2){
          this.lishitotal3 = res.data.totalRow
        }
        this.capitalListTotal = res.data.totalRow
      } else {
        this.$message.error(res.message)
      }
    },

    // 獲取當前委托手機用
    async getSellOrdersByStateMb() {
      let productType = 1;
      let symbol = '';
      switch (this.items.strategyType) {
        case 0:
          productType = 3;
          symbol = this.items.belong_to;
          break;
        case 1:
          productType = 2;
          symbol = this.items.coin;
          break;
        case 2:
          productType = 1;
          symbol = this.items.coin;
          break;
      }
      const res = await getOrderListByStateAndSymbol(this.lishijiaoyi,'','',productType, symbol, this.pageNumofMb,10,this.robotType,this.items.uid_one, this.items.uid_two)
      if (res.success) {
        this.totalPageofMb = res.data.totalPage
        this.totalRowofMb = res.data.totalRow
        this.lishijiaoyiObj = res.data
        this.lishijiaoyiList_1_mb = res.data.list
        this.lishijiaoyiList_1_mb.forEach((item, index) => {
          if (item.side == 'buy') {
            item.sideName = this.$t('robot.buy');
          } else if (item.side == 'sell' || item.side == 'ration') {
            item.sideName = this.$t('robot.sell');
          }
        })
        if(this.lishijiaoyi == 0){
          this.lishitotal = res.data.totalRow // title展示用
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // 獲取當前委托手機用
    async getSellOrdersByState2Mb() {
      let productType = 1;
      let symbol = '';
      switch (this.items.strategyType) {
        case 0:
          productType = 3;
          symbol = this.items.belong_to;
          break;
        case 1:
          productType = 2;
          symbol = this.items.coin;
          break;
        case 2:
          productType = 1;
          symbol = this.items.coin;
          break;
      }
      const res = await getOrderListByStateAndSymbol(this.lishijiaoyi,this.date1,this.date2,productType, symbol, this.pageNumofMb,10,this.robotType,this.items.uid_one, this.items.uid_two)
      if (res.success) {
        this.totalPageofMb = res.data.totalPage
        this.totalRowofMb = res.data.totalRow
        this.lishijiaoyiList_1_mb = res.data.list
        this.lishijiaoyiList_1_mb.forEach((item, index) => {
          if (item.side == 'buy') {
            item.sideName = this.$t('robot.buy');
          } else if (item.side == 'sell' || item.side == 'ration') {
            item.sideName = this.$t('robot.sell');
          }
        })
        if(this.lishijiaoyi == 1){
          this.lishitotal2 = res.data.totalRow
        }else if(this.lishijiaoyi == 2){
          this.lishitotal3 = res.data.totalRow
        }
      } else {
        this.$message.error(res.message)
      }
    },
    handleRecommendCurrentChange(e) {
      this.pageNum = e;
      if (this.lishijiaoyi == 0) {
        this.getSellOrdersByState()
      } else {
        this.getSellOrdersByState2()
      }
    },
    loadLishijiaoyi_1_mb(num){
      this.pageNumofMb = num;
      if (this.lishijiaoyi == 0){
        this.getSellOrdersByStateMb();
      }else{
        this.getSellOrdersByState2Mb();
      }
    },
    stateFormat2(row, column) {
      if (row.side === 'buy') {
        return row.price
      } else {
        return row.price
      }
    },
    stateFormat3(row, column) {
      if (row.state === '-1') {
        return this.$t('robot.cancelled')
      } else if (row.state == '0') {
        return this.$t('robot.pending_order')
      } else {
        return this.$t('robot.completed')
      }
    },
    delet(strategyType, item){
      switch (strategyType) {
        case 0:
          this.deleteSj(item.group_id)
          break;
        case 1:
          this.stopState = '3'
          this.delBBItem = item
          this.delBb(item.coin)
          break;
        case 2:
          this.delUb(item.id)
          break;
      }
    },
    // 刪除黃金三角
    deleteSj: function (id) {
      // this.$confirm(this.$t('robot.are_you_sure_del_sj'))
      //     .then(async _ => {
      //       const res = await delSj(id)
      //       if (res.success) {
      //         this.$message.success(this.$t('robot.del_success'))
      //         this.$router.back()
      //         // await this.getSj();
      //         // await this.getSymbolCount();
      //       } else {
      //         this.$message.error(res.message)
      //       }
      //     })
      //     .catch(_ => {});
      // this.$alert(`<span style="display: block">${this.$t(`robot.are_you_sure_del_sj`)}</span>` + (this.robotState == 2?`<span style="display: block;margin-top: 5px;color: #FF4D4F;line-height: 16px">机器人关闭状态删除策略将不执行撤单，如需撤单至交易所手动撤单</span>`: ''), '', {
      this.$alert((this.robotState == 2?`<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.delete_sj_for_closed_bot`)}</span>`: `<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.delete_sj_for_started_bot`)}</span>`), '', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true
      }).then(async _ => {
        const res = await delSj(id)
        if (res.success) {
          this.$message.success(this.$t('robot.del_success'))
          this.$router.back()
        } else {
          this.$message.error(res.message)
        }
      }).catch(_ => {});
    },
    delBb(coin) {
      this.delBbStrategy = true
      // this.$confirm(this.$t('robot.are_you_sure_del_bb'))
      //     .then(async _ => {
      //       const res = await delBb(coin)
      //       if (res.success) {
      //         this.$message.success(this.$t('robot.del_success'))
      //         this.$router.back()
      //         // await this.getSj();
      //         // await this.getSymbolCount();
      //       } else {
      //         this.$message.error(res.message)
      //       }
      //     })
      //     .catch(_ => {});
    },
    delBbNew(){
      if (this.robotState == 2){
        this.stopState = '2'
      }

      delBb(this.delBBItem.coin, this.stopState).then(res => {
        if(!res) return
        if(res.success){
          this.$message.success(this.$t('robot.del_success'))
          this.delBbStrategy = false
          this.$router.back()
          // this.getUserStrategyList()
          // this.getSymbolCount();
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    delUb(id) {
      this.$confirm(this.$t('robot.are_you_sure_del_ub'))
          .then(async _ => {
            const res = await delUb(id)
            if (res.success) {
              this.$message.success(this.$t('robot.del_success'))
              this.$router.back()
              // await this.getSj();
              // await this.getSymbolCount();
            } else {
              this.$message.error(res.message)
            }
          })
          .catch(_ => {});
    },
    celueType(){

    },
    getRuntime(){
      this.runTime = this.$util.formatDuring(new Date().getTime() - new Date(this.items.add_time))
      this.runTimeToHour = this.$util.formatDuringToHour(new Date().getTime() - new Date(this.items.add_time))
    },

  },

}
</script>

<style scoped>

.detail_title {
  position: relative;
}

.detail_title img:nth-of-type(1) {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.detail_title span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: black;
  margin-top: 2px;
  vertical-align: middle;
}

.detail_title span:nth-of-type(2) {
  font-size: 14px;
  font-family: PingFangTC-Regular, PingFangTC;
  font-weight: 400;
  display: inline-block;
  color: #E3B936;
  vertical-align: middle;
  background: #FEFBF3;
  padding: 6px 12px;
  border-radius: 2px;
  margin-left: 9px;
}

.detail_title img:nth-of-type(2) {
  width: 14px;
  height: 14px;
  margin-left: 24px;
  vertical-align: middle;
}

.detail_title span:nth-of-type(3) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #72777E;
  margin-left: 12px;
  vertical-align: middle;
}

.detail_title div {
  display: inline-block;
  position: absolute;
  right: 0px;
}

.detail_title_button_w {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #AB54DB !important;
  padding: 9px 17px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #AB54DB;
}

.detail_title_button_b {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  padding: 10px 18px;
  background: #AB54DB;
  border-radius: 2px;
  border: unset;
}

.total_revenue {

}

.total_revenue_content {
  vertical-align: top;
  width: 49%;
  display: inline-block;
}

.total_revenue_content div:nth-of-type(1)>div {
  display: inline-block;
  width: 48.5%;
}

.total_revenue_content div:nth-of-type(1)>div:nth-of-type(2) {
  text-align: right;
  display: inline-block;
  padding-right: 3%;
}

.total_revenue_content span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #72777E;
  display: block;
}

.total_revenue_content_bottom{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1E2329;
}

.side_color_red {
  color: #FF4D4D
}

.side_color_green {
  color: #52C41A
}

>>>.el-tabs__nav-wrap::after{
  height: 1px;
}

>>>.el-tabs__item.is-active {
  color: #AB54DB;
}

>>>.el-tabs__active-bar{
  background-color: #AB54DB;
}

>>>.el-table .el-table__cell {
  padding: 8px 0;
}

@media screen and (max-width: 900px) {
  .robot-pc {
    display: none;
  }
  >>>.el-tabs__item {
    padding: 0 9px;
  }
  .auto-strategy-position-detail{
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 88%;
    vertical-align: middle;
  }
  .edit_bb_dialog >>>.el-dialog__body{
    padding: 0 20px !important;
  }
  .edit_bb_dialog >>>.el-dialog{
    /*min-height: 260px;*/
  }
}

@media screen and (min-width: 900px) {
  .robot-pc {
    display: block;
  }
  .edit_bb_dialog >>>.el-dialog__body{
    padding: 0 20px
  }
}

.el-divider--horizontal {
  margin: 10px 0 15px;
}

.array-android-css{
  margin-top: 4px !important;
  line-height: 6px;
}
.array-android-css2{
  line-height: 6px !important;
  margin-top: -3px !important;
}

</style>
